/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout/DashboardLayout";
import Modal from "../../../components/Modal";
import { Pagination } from "../../../components/Pagination";
import { useLocation } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import style from "./index.module.scss";
import Construction from "../../../assets/images/under-construction.svg";
import { ScaleLoader } from "react-spinners";
import Dialog from "../../../components/Dialog";

const Cards = ({ userInfo }) => {
  console.log(userInfo);
  const cards = [
    // {
    //   description: "Pending Request",
    //   amt: `${userInfo ? userInfo.pending : "..."}`,
    // },
    // {
    //   description: "Active Templates",
    //   amt: `${userInfo ? userInfo.active : "..."}`,
    // },
    {
      description: "Total Templates",
      amt: `${userInfo ? userInfo.total : "..."}`,
    },
  ];

  return (
    <div>
      <ul className={style["cards--container"]}>
        {cards.map((item) => (
          <li className={style["cards--item"]}>
            <header>
              <h2>{item.amt}</h2>
              <p>{item.description}</p>
            </header>
          </li>
        ))}
      </ul>
    </div>
  );
};

const WhatsappTemplate = () => {
  const location = useLocation();
  const [templates, setTemplates] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [isModalOpen, setIsModalopen] = useState(false);
  const [modalOpen, setModalopen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [requestData, setRequestData] = useState({
    template: "",
    message: "",
  });

  const [data, setData] = useState(null);

  const { token, authState } = useSelector((state) => state.auth);
  const th = [
    {
      name: "S/N",
    },
    {
      name: "Template",
    },
    {
      name: "Message",
    },
    {
      name: "Status",
    },
    {
      name: "Date",
    },
  ];

  const config = {
    headers: {
      Authorization: "Bearer XYRQTlpabh3V6EsZ",
    },
  };
  const fetchTemplates = async () => {
    setIsLoading(true);
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/whatsapp-template/dev/all
          `,
        {
          client_id: authState.id,
          token,
        },
        config
      );
      if (res.status === 200) {
        if (res.data.data.length <= 0) {
          setNotFound("No Templates found yet. :(");
          console.log(res.data);
        } else {
          setTemplates(res.data);
          console.log(res.data);
        }
      } else {
        // setTemplates(res.data);
        console.log(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setNotFound("No Templates found yet. :(");
    }
  };
  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/whatsapp-template/dev/request`,
        {
          template: requestData.template,
          message: requestData.message,
          client_id: authState.id,
          token,
        },
        config
      );
      if (res.status) {
        setIsLoading(false);
        setModalopen(true);
        setIsModalopen(false);
        fetchTemplates();
      } else {
        setIsLoading(false);
        console.log(res.data);
      }
      setIsLoading(false);
      setModalopen(true);
    } catch (err) {
      console.log(err.message);
      setData({ ...data, loading: false, error: "Error making request" });
    }
  };

  return (
    <Layout
      pageTitle={
        location.pathname === "/user/whatsapp-template"
          ? "Whatsapp Template"
          : null
      }
    >
      <Dialog isOpen={isLoading}>
        <ScaleLoader />
      </Dialog>
      <Modal isOpen={modalOpen} toggleModal={() => setModalopen(!modalOpen)}>
        <div className={style.top}>
          <h2>Your Request is Under Review</h2>
          <p>We will send you a confrimation mail of your request</p>
        </div>
        <form>
          <Button
            type="button"
            className="large--filled"
            onClick={() => setModalopen(false)}
          >
            Ok
          </Button>
        </form>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        toggleModal={() => setIsModalopen(!isModalOpen)}
      >
        <div className={style.top}>
          <h2>Template details</h2>
          <p>Please enter your customized WhatsApp Template</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Template Title</label>
            <input
              required
              value={requestData.template}
              onChange={(e) =>
                setRequestData((prev) => ({
                  ...prev,
                  template: e.target.value,
                }))
              }
              type="text"
              placeholder="Raah Cosmetics"
            />
          </div>
          <div>
            <label>Message</label>
            <textarea
              required
              rows="5"
              type="text"
              placeholder="Raah Cosmetics"
              value={requestData.message}
              onChange={(e) =>
                setRequestData((prev) => ({
                  ...prev,
                  message: e.target.value,
                }))
              }
            />
          </div>

          <Button
            onClick={() => {
              setIsModalopen(false);
            }}
            type="submit"
            className="large--filled"
          >
            {requestData.loading ? "Requesting..." : "Request"}
          </Button>
        </form>
      </Modal>
      {notFound ? (
        <main className={style["main"]}>
          <div>
            <img src={Construction} alt="under construction" />
          </div>
          <div className={style["create"]}>
            <h4>No WhatsApp Template created yet.</h4>
            <span>
              Create one <span onClick={() => setIsModalopen(true)}>here</span>
            </span>
          </div>
        </main>
      ) : (
        <div style={{ padding: "2rem" }}>
          <div className={style["actionarea"]}>
            <Button
              type="button"
              className="medium--filled"
              onClick={() => setIsModalopen(true)}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  fill="white"
                  viewBox="0 0 24 24"
                >
                  <path
                    style={{ fill: "white" }}
                    d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
                  />
                </svg>
              </span>
              Request a Template
            </Button>
          </div>

          <Cards
            userInfo={
              templates && {
                total: templates.total,
              }
            }
          />

          <div className={style["table--container"]}>
            <table className={style["HistoryTable--table"]}>
              {" "}
              <thead>
                <tr>
                  {th.map((item) => {
                    return <th key={item.name.toLowerCase()}>{item.name}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {templates &&
                  templates.data.map((template, index) => (
                    <tr key={template.id} className={style["table-row"]}>
                      <td>{index + 1}</td>
                      <td>{template.template}</td>
                      <td>{template.message}</td>
                      <td>
                        {template.status.toLowerCase() === "new" ? (
                          <span className={style["new"]}>
                            {template.status}
                          </span>
                        ) : template.status.toLowerCase() === "rejected" ? (
                          <span className={style["pending"]}>
                            {template.status}
                          </span>
                        ) : (
                          <span className={style["confirmed"]}>
                            {template.status}
                          </span>
                        )}
                      </td>
                      <td>
                        {dayjs(template.created_at).format("DD-MMM-YYYY")}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className={style["paginate"]}>
            {templates && (
              <Pagination
                numberOfPages={templates.from}
                currentPage={templates.current_page}
                nextPage={templates.to}
              />
            )}
          </div>
        </div>
        // <div>getting something</div>
      )}
      )
    </Layout>
  );
};

export default WhatsappTemplate;
