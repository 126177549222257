/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import React, { useEffect } from "react";
// import DatePicker from "react-datepicker";
import Button from "../../../../components/Button";
import DatePickerField from "../../../../components/DatePickerField";
import FileUpload from "../../../../components/FIleUpload";
import { FormRowCheckbox, FormRowGroup } from "../../../../components/Form-row";
import Layout from "../../../../components/Layout/DashboardLayout";
import { sendBusinessMessage } from "../../../../utils/ValidationSchemas";
import * as messagingActions from "../../../../redux/actions/sendMessagesActions";
// import * as phoneBookActions from "../../../../redux/actions/createPhonebookActions";
import { useSelector, useDispatch } from "react-redux";
import style from "./index.module.scss";
import dayjs from "dayjs";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "../../../../components/Modal";
import doubleTickIcon from "../../../../assets/icons/doubletick.png";
import { clearErrorMessage } from "../../../../redux/actions/messageActions";
import axios from "axios";
// import Dialog from "../../../../components/Dialog";
import MicRecorder from "mic-recorder-to-mp3";
import { formatMinutes, formatSeconds } from "../../../../utils/format-time";
import TagInput from "../../../../components/TagInput";
import { apiErrorHandler } from "../../../../utils/apiErrorHandler";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function DevelopMessaging() {
  return (
    <Layout pageTitle={"Business Messaging"}>
      {/* <div className={style["back--arrow"]}>
       <Link to={"/user/send-messages"}>
        <span>← Back</span></Link>
     
       </div> */}
      <main className={style["message__form--container"]}>
        <MessageForm />
      </main>
    </Layout>
  );
}

const ProgressBar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    width: "384px",
    backgroundColor: "whitesmoke",
    borderRadius: 40,
    margin: 50,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 10,
    color: "black",
    fontWeight: 900,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}></span>
      </div>
    </div>
  );
};

const BusinessMessageHeader = () => {
  const { values } = useFormikContext();
  const notification = useSelector((state) => state.message);

  return (
    <>
      <header>
        <h3>Business Messaging </h3>
        <p>
          Send messages to hundreds and thousands of your customers at a go in
          your voice.
        </p>
      </header>
      <hr />

      <div>
        {notification.error && (
          <ul className="signup__errors">
            {notification.error && (
              <li>{apiErrorHandler(notification?.msg.error)}</li>
            )}
          </ul>
        )}
        <h5 htmlFor="select message channel">Select Message channel</h5>
        <div className={style["recepient--type"]}>
          <div className={style["radio"]}>
            <label htmlFor="voicecall">Send as Voice call</label>
            <Field
              type="radio"
              name="channel"
              value="voice"
              checked={values.channel === "voice"}
              id="voicecall"
            />
          </div>

          <div className={style["radio"]}>
            <label htmlFor="sms">Send as SMS</label>
            <Field
              type="radio"
              name="channel"
              value="sms"
              checked={values.channel === "sms"}
              id="sms"
            />
          </div>

          <div className={style["radio"]}>
            <label htmlFor="whatsapp">Send as WhatsApp message</label>
            <Field
              type="radio"
              name="channel"
              value="whatsapp"
              checked={values.channel === "whatsapp"}
              id="whatsapp"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Recipient = () => {
  const { values } = useFormikContext();
  return (
    <>
      <h4
        htmlFor="select message recipient"
        style={{
          color: "#000",
          fontWeight: "bold",
          paddingTop: "2rem",
          fontSize: "20px",
          fontStyle: "normal",
        }}
      >
        Recipients
      </h4>
      <div className={style["recepient--type"]}>
        <div className={style["radio"]}>
          <label htmlFor="phonebook">Use a phonebook</label>
          <Field
            type="radio"
            name="recipient"
            value="phonebook"
            checked={values.recipient === "phonebook"}
            id="phonebook"
          />
        </div>
        <div className={style["radio"]}>
          <label htmlFor="numbers">Enter Phone numbers</label>
          <Field
            type="radio"
            name="recipient"
            value="numbers"
            checked={values.recipient === "numbers"}
            id="numbers"
          />
        </div>
      </div>
    </>
  );
};

const MessageForm = () => {
  // const [messageCount, setMessageCount] = React.useState({ count: 1 });
  const [message, setMessageInfo] = React.useState({
    count: 1,
    charLeft: 160,
  });
  const user = useSelector((state) => state.auth);
  const isSending = useSelector((state) => state.businessmessaging.isSending);
  const notification = useSelector((state) => state.message);
  const [senderIDs, setSenderIDs] = React.useState([]);
  const [templates, setTemplates] = React.useState([]);
  const [template, setTemplate] = React.useState();
  const [phonebooks, setPhonebooks] = React.useState(null);
  // const [savePhonebookModal, setSavePhonebookModal] = React.useState(false);
  // const [numbers, setNumbers] = React.useState();
  const [isRecording, setIsRecording] = React.useState(false);
  const [blobURL, setBlobURL] = React.useState("");
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [audioFile, setAudioFile] = React.useState(null);
  const [recordingTime, setRecordingTime] = React.useState({
    recordingMinutes: 0,
    recordingSeconds: 0,
  });
  const [tags, setTags] = React.useState([]);
  const [recipient, setRecipient] = React.useState();

  // Audio recorder starts here
  useEffect(() => {
    navigator?.mediaDevices?.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setIsBlocked(false);
      },
      () => {
        console.log("Permission Denied");
        setIsBlocked(true);
      }
    );
  });

  useEffect(() => {
    const MAX_RECORDER_TIME = 5;
    let recordingInterval = null;

    if (isRecording)
      recordingInterval = setInterval(() => {
        setRecordingTime((prevState) => {
          if (
            prevState.recordingMinutes === MAX_RECORDER_TIME &&
            prevState.recordingSeconds === 0
          ) {
            clearInterval(recordingInterval);
            return prevState;
          }

          if (
            prevState.recordingSeconds >= 0 &&
            prevState.recordingSeconds < 59
          )
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };

          if (prevState.recordingSeconds === 59)
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };
        });
      }, 1000);
    else clearInterval(recordingInterval);

    return () => clearInterval(recordingInterval);
  });

  const startRecording = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const saveRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });

        setAudioFile(file);
        const blobURL = URL.createObjectURL(file);
        setBlobURL(blobURL);
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  // Audio recorder ends here

  const initialValues = {
    channel: "sms",
    recipient: "numbers",
    phonebook_id: "",
    client_id: user?.authState.id,
    token: user?.token,
    message_audio: "",
    job_title: "",
    upload_method: "",
    msisdns: "",
    message_text: "",
    sender_id: "",
    template_id: "",
    template: "",
    run_time: new Date(),
  };

  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  };

  useEffect(() => {
    const getClientIDs = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/senderid/dev/bystatus`,
          {
            client_id: user.authState.id,
            token: user.token,
            status: "verified",
          },
          config
        );

        if (response.status) {
          initialValues.sender_id = response.data.data[0].sender_id;
          setSenderIDs(response.data.data);
        } else {
          setSenderIDs(response.data.data);
        }
      } catch {
        console.log("error");
      }
    };
    getClientIDs();
  }, []);

  useEffect(() => {
    const getTemplates = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/whatsapp-template/dev/bystatus`,
          {
            client_id: user.authState.id,
            token: user.token,
            status: "verified",
          },
          config
        );

        if (response.status) {
          setTemplates(response.data.data);
        } else {
          setTemplates(response.data.data);
        }
      } catch {
        console.log("error");
      }
    };
    getTemplates();
  }, []);

  useEffect(() => {
    const getAllPhonebook = async () => {
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/phonebook/dev/client/summary`,
          {
            client_id: user.authState.id,
            token: user.token,
          }
        );
        if (res.status === 200) {
          setPhonebooks(res.data);
        } else {
          setPhonebooks(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllPhonebook();
  }, []);

  const dispatch = useDispatch();

  const handleToggle = (props) => {
    setTags([]);
    props.resetForm();
    dispatch(clearErrorMessage());
  };

  // const handleCreatePhonebookToggle = (props) => {
  //   props.resetForm();
  //   dispatch(clearErrorMessage());
  //   setSavePhonebookModal(true);
  // };

  // const handleCloseToggle = (props) => {
  //   setSavePhonebookModal(false);
  //   props.resetForm();
  //   // dispatch(clearErrorMessage());
  // };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  // const handleReciepientCount = (values) => {
  //   const divisor = values.msisdns.length / 13;

  //   return divisor;
  //   // return values.msisdns
  //   //   ?.trim()
  //   //   .split(",")
  //   //   .filter((i) => i.length).length;
  // };

  const handleMessageCount = (value) => {
    const { message_text } = value;
    let chars = message_text.length,
      messages = Math.ceil(chars / 160),
      remaining = messages * 160 - (chars % (messages * 160) || messages * 160);

    setMessageInfo({
      ...message,
      charLeft: remaining,
      count: messages,
    });

    if (value.message_text?.length < 1) {
      setMessageInfo({ count: 1, charLeft: 160 });
    }
  };

  const filterTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    // console.log(dayjs(selectedDate.getTime()).add(1,'h'))
    return dayjs(currentDate).add(30, "minute") < dayjs(selectedDate);
  };

  // Todo
  //Add play feature

  const handleAudio = (values) => {
    const { message_audio } = values;
    const audio = message_audio && URL.createObjectURL(message_audio);
    return audio;
  };

  const handPause = () => {
    setRecordingTime({
      recordingMinutes: 0,
      recordingSeconds: 0,
    });
    setBlobURL(null);
    setAudioFile(null);
  };

  const fetchTemplateData = async (template_id) => {
    let selectedTemplate = templates.find(
      (prop) => prop.template_id === template_id
    );
    setTemplate(selectedTemplate);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={sendBusinessMessage}
        onSubmit={(values, actions) => {
          const formData = new FormData();
          formData.append("client_id", values.client_id);
          formData.append("token", values.token);
          formData.append("name", values.job_title);

          if (values.schedule) {
            formData.append(
              "run_time",
              dayjs(values.run_time).format("YYYY-MM-DD HH:mm:DD")
            );
          }

          if (values.channel === "sms") {
            formData.append("message_text", values.message_text);
          }

          if (values.recipient === "numbers" && tags.length > 0) {
            formData.append("msisdns", tags.toString());
          }
          if (values.recipient === "numbers" && tags.length === 0) {
            formData.append("msisdns", recipient);
          }

          if (values.recipient === "phonebook") {
            formData.append("phonebook_id", values.phonebook_id);
          }
          if (values.channel === "sms" && values.sender_id) {
            formData.append("sender_id", values.sender_id);
          }
          if (values.channel === "whatsapp") {
            formData.append("template", template?.template);
          }
          if (values.channel === "voice" && values.upload_method === "tts") {
            formData.append("message_text", values.message_text);
          }

          if (values.channel === "voice" && values.upload_method === "audio") {
            formData.append("message_audio", values.message_audio);
          }
          if (values.channel === "voice" && values.upload_method === "record") {
            formData.append("message_audio", audioFile);
          }
          // if (values.recipient === "numbers" && tags.toString().includes(",")) {
          //   setNumbers(tags.toString());
          // }
          if (
            values.channel === "sms" &&
            values.recipient === "phonebook" &&
            values.message_text.includes("(name)")
          ) {
            dispatch(messagingActions.sendRichBusinessMessageSms(formData));
          } else if (
            values.channel === "voice" &&
            values.upload_method &&
            (values.recipient === "numbers" || values.recipient === "phonebook")
          ) {
            dispatch(messagingActions.sendBusinessMessageVoice(formData));
          } else if (
            values.channel === "sms" &&
            values.recipient &&
            !values.message_text.includes("(name)")
          ) {
            dispatch(messagingActions.sendBusinessMessageSms(formData));
          } else {
            dispatch(messagingActions.sendBusinessMessageWhatsApp(formData));
          }
        }}
      >
        {({ values, setFieldValue, submitForm, ...props }) => (
          <Form>
            <BusinessMessageHeader />
            <ErrorMessage name="channel" component="span" className="error" />
            <div className={style["form--section"]}>
              <div className={style["form__group"]} style={{ height: "133px" }}>
                <label htmlFor="Job title">Name of Message</label>
                <Field
                  name="job_title"
                  placeholder="eg. OTP Campaign one"
                  onChange={({ target }) => {
                    setFieldValue("job_title", target.value);
                  }}
                />
                <ErrorMessage
                  name="job_title"
                  component="span"
                  className="error"
                />
              </div>
              {values.channel === "sms" && (
                <div
                  className={style["form__group"]}
                  style={{ height: "133px" }}
                >
                  <label htmlFor="senderID">Reference Sender ID</label>
                  <Field
                    as="select"
                    name="sender_id"
                    // onChange={({ target }) => {
                    //   setFieldValue("sender_id", target.value);
                    // }}
                  >
                    <option value="">Default None</option>
                    {senderIDs.map((sender, index) => (
                      <option value={sender.sender_id} key={index}>
                        {sender.sender_id}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="sender_id"
                    component="span"
                    className="error"
                  />
                </div>
              )}

              {values.channel === "whatsapp" && (
                <div
                  className={style["form__group"]}
                  style={{ height: "133px" }}
                >
                  <label htmlFor="template">WhatsApp Template</label>
                  <Field
                    as="select"
                    name="template_id"
                    onChange={({ target }) => {
                      setFieldValue("template_id", target.value);
                      fetchTemplateData(target.value);
                    }}
                  >
                    <option value="">Default None</option>
                    {templates?.map((template, index) => (
                      <option
                        value={template.template_id}
                        key={template.template_id}
                      >
                        {template.template}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="template_id"
                    component="span"
                    className="error"
                  />
                </div>
              )}

              {values.channel === "voice" && (
                <div
                  className={style["form__group"]}
                  style={{ height: "133px" }}
                >
                  <label htmlFor="voicecall">Upload type</label>
                  <Field as="select" name="upload_method">
                    <option value="">Please select a method of upload</option>
                    <option value="audio">Upload as Audio</option>
                    <option value="record">
                      Record your own audio and upload
                    </option>
                    <option value="tts">
                      Type in text to convert to audio
                    </option>
                  </Field>
                  <ErrorMessage
                    name="upload_method"
                    component="span"
                    className="error"
                  />
                </div>
              )}
            </div>

            <>
              <Recipient />
              {values.recipient === "phonebook" && values.channel === "sms" && (
                <div className={style["notice"]}>
                  <div className={style["requirement--head"]}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        style={{ fill: "#9B6E00" }}
                        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 9V15H11V9H9ZM9 5V7H11V5H9Z"
                        fill="#9B6E00"
                      />
                    </svg>
                    <h4>Want to personalize this message?</h4>
                  </div>
                  <p className={style["requirement--list"]}>
                    If your phonebook contains the name attribute, you can
                    personalize this message to all your contacts <br />
                    Simply use the (name) placeholder wherever you want to
                    mention your contacts by name.
                    <br />
                    <strong> NB:</strong> This feature is in beta and does not
                    currently support custom sender IDs
                  </p>
                </div>
              )}
              {values.recipient === "phonebook" && phonebooks && (
                <div className={style["form--section"]}>
                  <div className={style["form__group"]}>
                    <label htmlFor="phonebook_id">Select a Phonebook</label>
                    <Field
                      as="select"
                      name="phonebook_id"
                      onChange={({ target }) => {
                        setFieldValue("phonebook_id", target.value);
                      }}
                    >
                      <option value="">Default None</option>
                      {phonebooks.data?.data?.map((phonebook, index) => (
                        <option value={phonebook.id} key={index}>
                          {phonebook.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="phonebook_id"
                      component="span"
                      className="error"
                    />
                  </div>
                </div>
              )}
            </>

            {values.recipient === "numbers" && values.channel === "sms" && (
              <div className={style["notice"]}>
                <div className={style["requirement--head"]}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      style={{ fill: "#9B6E00" }}
                      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 9V15H11V9H9ZM9 5V7H11V5H9Z"
                      fill="#9B6E00"
                    />
                  </svg>
                  <h4>Important</h4>
                </div>
                <ol className={style["requirement--list"]}>
                  <li>
                    Please fill in the recipients number alongside it's dialing
                    code eg. +23408142394015, +12345678.
                    <br />
                  </li>
                  <li>
                    Please press enter or input comma after each number. eg.
                    recipient one, recipient two.
                  </li>
                </ol>
              </div>
            )}

            {values.recipient === "numbers" &&
              values.channel === "whatsapp" && (
                <div className={style["notice"]}>
                  <div className={style["requirement--head"]}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        style={{ fill: "#9B6E00" }}
                        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 9V15H11V9H9ZM9 5V7H11V5H9Z"
                        fill="#9B6E00"
                      />
                    </svg>
                    <h4>Important</h4>
                  </div>
                  <ol className={style["requirement--list"]}>
                    <li>
                      Please fill in the recipients number alongside it's
                      dialing code eg. +23408142394015, +12345678.
                      <br />
                    </li>
                    <li>
                      Each number to be sent an sms should have a comma. eg.
                      recipient one, recipient two.
                    </li>
                  </ol>
                </div>
              )}

            <div className={style["form--section"]}>
              {values.channel === "voice" &&
                values.upload_method === "audio" && (
                  <div className={style["form__group"]}>
                    <label htmlFor="audio">Upload Audio File</label>
                    <FileUpload
                      setFieldValue={setFieldValue}
                      values={values}
                      name="message_audio"
                      accept="audio/mp3, audio/*"
                      // notification={notification}
                    >
                      <audio src={handleAudio(values)} controls></audio>
                      <p className={style["filename"]}>
                        {values.message_audio?.name}
                      </p>
                    </FileUpload>
                    <ErrorMessage
                      name="message_audio"
                      component="span"
                      className="error"
                    />
                  </div>
                )}

              {values.channel === "voice" &&
                values.upload_method === "record" && (
                  <div className={style["form__group"]}>
                    <label htmlFor="sms">Record your audio</label>

                    <div className="controls-container">
                      <div className="recorder-display">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            style={{ fill: "#2948EB" }}
                            d="M20.4853 3.51469C18.2188 1.24823 15.2053 0 12 0C8.79469 0 5.78123 1.24823 3.51469 3.51469C1.24823 5.78123 0 8.79469 0 12C0 15.2053 1.24823 18.2188 3.51469 20.4853C5.78123 22.7518 8.79469 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51469ZM5.625 13.125C5.625 13.7463 5.12133 14.25 4.5 14.25C3.87867 14.25 3.375 13.7463 3.375 13.125V10.875C3.375 10.2537 3.87867 9.75 4.5 9.75C5.12133 9.75 5.625 10.2537 5.625 10.875V13.125ZM9.375 15C9.375 15.6213 8.87133 16.125 8.25 16.125C7.62867 16.125 7.125 15.6213 7.125 15V9C7.125 8.37867 7.62867 7.875 8.25 7.875C8.87133 7.875 9.375 8.37867 9.375 9V15ZM13.125 17.25C13.125 17.8713 12.6213 18.375 12 18.375C11.3787 18.375 10.875 17.8713 10.875 17.25V6.75C10.875 6.12867 11.3787 5.625 12 5.625C12.6213 5.625 13.125 6.12867 13.125 6.75V17.25ZM16.875 15C16.875 15.6213 16.3713 16.125 15.75 16.125C15.1287 16.125 14.625 15.6213 14.625 15V9C14.625 8.37867 15.1287 7.875 15.75 7.875C16.3713 7.875 16.875 8.37867 16.875 9V15ZM19.5 14.25C18.8787 14.25 18.375 13.7463 18.375 13.125V10.875C18.375 10.2537 18.8787 9.75 19.5 9.75C20.1213 9.75 20.625 10.2537 20.625 10.875V13.125C20.625 13.7463 20.1213 14.25 19.5 14.25Z"
                            fill="#2948EB"
                          />
                        </svg>
                        <ProgressBar
                          bgcolor="#2948EB"
                          progress={formatSeconds(
                            recordingTime.recordingSeconds
                          )}
                          height={4}
                        />

                        <div className="recording-time">
                          <span>
                            {formatMinutes(recordingTime.recordingMinutes)}
                          </span>
                          <span>:</span>
                          <span>
                            {formatSeconds(recordingTime.recordingSeconds)}
                          </span>
                        </div>
                      </div>

                      <div className="start-button-container">
                        {isRecording ? (
                          <div
                            className="recording--filled"
                            title="Save recording"
                            disabled={isRecording === 0}
                            onClick={saveRecording}
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="0.5"
                                width="20"
                                height="20"
                                rx="4"
                                fill="white"
                              />
                            </svg>

                            <span>Stop Recording</span>
                          </div>
                        ) : blobURL ? (
                          <div className="action-buttons">
                            <div
                              className="cancel-button"
                              title="Start recording"
                              onClick={handPause}
                            >
                              Cancel
                            </div>

                            <audio src={blobURL} controls></audio>
                          </div>
                        ) : (
                          <div
                            className="recording--filled"
                            title="Start recording"
                            onClick={startRecording}
                          >
                            <svg
                              width="23"
                              height="23"
                              viewBox="0 0 23 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_1050_1543)">
                                <path
                                  style={{ fill: "#fff" }}
                                  d="M18.2669 11.8669C18.2669 11.4214 17.9262 11.0807 17.4807 11.0807C17.0352 11.0807 16.6945 11.4214 16.6945 11.8669C16.6945 14.9332 14.2047 17.423 11.1383 17.423C8.07201 17.423 5.58225 14.9332 5.58225 11.8669C5.58225 11.4214 5.24154 11.0807 4.79601 11.0807C4.35047 11.0807 4.00977 11.4214 4.00977 11.8669C4.00977 15.5098 6.7354 18.5761 10.3521 18.9693V21.0659H7.49543C7.0499 21.0659 6.70919 21.4066 6.70919 21.8522C6.70919 22.2977 7.0499 22.6384 7.49543 22.6384H14.7813C15.2268 22.6384 15.5675 22.2977 15.5675 21.8522C15.5675 21.4066 15.2268 21.0659 14.7813 21.0659H11.9246V18.9693C15.5413 18.5761 18.2669 15.5098 18.2669 11.8669Z"
                                  fill="white"
                                />
                                <path
                                  style={{ fill: "#fff" }}
                                  d="M11.1394 0.361588C8.7283 0.361588 6.7627 2.32719 6.7627 4.73833V11.8407C6.7627 14.278 8.7283 16.2174 11.1394 16.2437C13.5506 16.2437 15.5162 14.278 15.5162 11.8669V4.73833C15.5162 2.32719 13.5506 0.361588 11.1394 0.361588Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1050_1543">
                                  <rect
                                    width="22.2768"
                                    height="22.2768"
                                    fill="white"
                                    transform="translate(0 0.361588)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>

                            <span>Start Recording</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <span className={style["requirements--info"]}>
                      5 minutes maximum
                      <ErrorMessage
                        name="message_audio"
                        component="span"
                        className="error"
                      />
                    </span>
                  </div>
                )}

              {values.channel === "voice" && values.upload_method === "tts" && (
                <div className={style["form__group"]}>
                  <label htmlFor="sms">Text to convert</label>
                  <TextArea
                    name="message_text"
                    placeholder="Please type in text to convert to text to speech"
                  />
                  <span className={style["requirements--info"]}>
                    <br />
                    <ErrorMessage
                      name="message_text"
                      component="span"
                      className="error"
                    />
                  </span>
                </div>
              )}

              {values.recipient === "numbers" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div className={style["form__group"]}>
                    <label htmlFor="sms">Recipients</label>

                    <TagInput
                      tagInputs={tags}
                      setTagInputs={setTags}
                      setRecipient={setRecipient}
                      recipient={recipient}
                    />

                    {/* <TextArea
                    name="msisdns"
                    placeholder={`Numbers without country code won’t receive a message`}
                    onChange={({ target }) => {
                      setFieldValue("msisdns", target.value);
                    }}
                  >
                   
                  </TextArea> */}
                  </div>
                  <span className={style["requirements--info"]}>
                    Number of recipients: {tags.length}
                    <ErrorMessage
                      name="msisdns"
                      component="span"
                      className="error"
                    />
                  </span>
                </div>
              )}

              {values.channel === "sms" && (
                <div className={style["form__group"]}>
                  <label htmlFor="message text">Compose Message</label>
                  <TextArea
                    name="message_text"
                    placeholder={
                      values.recipient === "numbers"
                        ? "What message would you like to send?"
                        : "Hello (name)...."
                    }
                    value={values.message_text}
                    onChange={({ target }) => {
                      setFieldValue("message_text", target.value);
                      // handleMessageCount(values);
                    }}
                    handleMessageCount={handleMessageCount}
                  >
                    <span className={style["requirements--info"]}>
                      {/* {setMessageLength(values)} */}
                      {message.charLeft} Characters remaining of&nbsp;
                      {message.count}
                      &nbsp;message(s)
                      <ErrorMessage
                        name="message_text"
                        component="span"
                        className="error"
                      />
                    </span>
                  </TextArea>
                </div>
              )}

              {values.channel === "whatsapp" && (
                <div className={style["form__group"]}>
                  <label htmlFor="message text">Compose Message</label>
                  <TextArea
                    name="template"
                    placeholder={
                      values.recipient === "numbers"
                        ? "What message would you like to send?"
                        : "Hello (name)...."
                    }
                    value={template && template.message ? template.message : ""}
                    onChange={({ target }) => {
                      setFieldValue("template", target.value);
                      // handleMessageCount(values);
                    }}
                    disabled
                    handleMessageCount={handleMessageCount}
                  >
                    <span className={style["requirements--info"]}>
                      {/* {setMessageLength(values)} */}
                      {message.charLeft} Characters remaining of&nbsp;
                      {message.count}
                      &nbsp;message(s)
                      <ErrorMessage
                        name="message_text"
                        component="span"
                        className="error"
                      />
                    </span>
                  </TextArea>
                </div>
              )}
            </div>

            <FormRowCheckbox>
              <Field type="checkbox" name="schedule" id="schedule" />
              <label htmlFor="schedule">
                I want to send this message later
              </label>
            </FormRowCheckbox>

            {values.schedule && (
              <FormRowGroup>
                <label htmlFor="selectdate">Select a date and time</label>
                <ul className={style["requirement--list"]}>
                  <li style={{ paddingLeft: "1rem" }}>
                    Please choose a time one(1) hour ahead of current time.
                  </li>
                </ul>
                <DatePickerField
                  name="run_time"
                  value={values.run_time}
                  dateFormat="Pp"
                  filterTime={filterTime}
                  minDate={new Date()}
                  showTimeSelect
                  timeIntervals={60}
                />

                <ErrorMessage name="time" component="span" className="error" />
              </FormRowGroup>
            )}
            <div className={style["form--section"]}>
              <Button
                type="button"
                onClick={submitForm}
                className="filled"
                disabled={isSending}
              >
                {!isSending ? (
                  "Send Business Message"
                ) : (
                  <ScaleLoader
                    css={override}
                    size={80}
                    color={"#fff"}
                    loading={true}
                  />
                )}{" "}
              </Button>
            </div>
            {/* <Dialog
              isOpen={values.msisdns.includes(",") && notification.success}
            >
              <h3>
                Message sent. <br />
                <br />
                Would you like to add the <br /> number(s) to a phonebook?
              </h3>
              <div className={style["save--modal"]}>
                <Button
                  className="medium--filled"
                  onClick={() => handleCreatePhonebookToggle(props)}
                >
                  Yes
                </Button>
                <Button
                  className="small--ghost"
                  onClick={() => handleToggle(props)}
                >
                  No
                </Button>
              </div>
            </Dialog> */}

            {/* <Modal
              isOpen={savePhonebookModal}
              toggleModal={() => handleCloseToggle(props)}
            >
              <ul className="signup__errors">
                {notification.error && (
                  <li>{notification?.msg?.payload?.msg?.error}</li>
                )}
              </ul>
              <Formik
                initialValues={{
                  client_id: user?.authState.id,
                  token: user.token,
                  msisdns: numbers,
                  name: "",
                }}
                onSubmit={(values) => {
                  dispatch(
                    phoneBookActions.createSingleOrBatchPhonebook(values)
                  );

                  setInterval(() => {
                    setSavePhonebookModal(false);
                    setNumbers("");
                    props.resetForm();
                  }, 1000);
                }}
              >
                <Form>
                  <h3>Phonebook Title</h3>
                  <p>Please enter the following details. </p>
                  <div className={style["form__group"]}>
                    <label htmlFor="name">Phonebook Name </label>
                    <Field name="name" placeholder="Raah Cosmetics" />
                  </div>

                  <Button type="submit" className="filled">
                    Next
                  </Button>
                </Form>
              </Formik>
            </Modal> */}

            <Modal
              isOpen={notification.success}
              toggleModal={() => handleToggle(props)}
            >
              {/* {!numbers ? ( */}
              <>
                <img src={doubleTickIcon} alt="doubleTickIcon" />
                <h3>
                  {values.schedule
                    ? "Message scheduled successfully"
                    : notification.msg}
                </h3>
                <p>Send more messages and engage better with your customers.</p>
                <Button
                  className="large--filled"
                  onClick={() => handleToggle(props)}
                >
                  Okay
                </Button>
              </>
              {/* ) : (
                <>
                  <span>
                    <svg
                      width="84"
                      height="84"
                      viewBox="0 0 84 84"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        style={{ fill: "#EB2931" }}
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M42.0004 83.6C53.0334 83.6 63.6145 79.2172 71.416 71.4157C79.2175 63.6142 83.6004 53.033 83.6004 42C83.6004 30.967 79.2175 20.3859 71.416 12.5844C63.6145 4.78287 53.0334 0.400024 42.0004 0.400024C30.9674 0.400024 20.3863 4.78287 12.5847 12.5844C4.78324 20.3859 0.400391 30.967 0.400391 42C0.400391 53.033 4.78324 63.6142 12.5847 71.4157C20.3863 79.2172 30.9674 83.6 42.0004 83.6ZM61.2768 35.2764C62.224 34.2957 62.7481 32.9822 62.7363 31.6187C62.7244 30.2553 62.1776 28.9511 61.2134 27.987C60.2493 27.0228 58.9451 26.476 57.5817 26.4641C56.2183 26.4523 54.9047 26.9764 53.924 27.9236L36.8004 45.0472L30.0768 38.3236C29.0961 37.3764 27.7825 36.8523 26.4191 36.8641C25.0557 36.876 23.7515 37.4228 22.7873 38.387C21.8232 39.3511 21.2763 40.6553 21.2645 42.0187C21.2526 43.3822 21.7768 44.6957 22.724 45.6764L33.124 56.0764C34.0991 57.0513 35.4215 57.5989 36.8004 57.5989C38.1792 57.5989 39.5016 57.0513 40.4768 56.0764L61.2768 35.2764Z"
                        fill="#EB2931"
                      />
                    </svg>
                  </span>
                  <h3 style={{ textAlign: "center" }}>
                    Contacts have been added <br /> successfully{" "}
                  </h3>
                </>
              )} */}
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const TextArea = ({ name, placeholder, children, ...props }) => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();

  React.useEffect(() => {
    props.handleMessageCount && props.handleMessageCount(values);
    dispatch(clearErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.message_text]);
  return (
    <>
      <Field as="textarea" {...props} name={name} placeholder={placeholder} />
      {children}
    </>
  );
};
