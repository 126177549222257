import React from "react";
import AccountLayout from "../../../../components/Layout/AccountLayout";
// import Layout from "../../components/Layout/DashboardLayout";
import style from "./index.module.scss";
import useCopyToClipboard from "../../../../Customhooks/useCopytoClipboard";
import Button from "../../../../components/Button";

export default function API() {
  const [isCopied, handleCopy] = useCopyToClipboard(2000);
  const [showAPikey, setApiKeyDisplay] = React.useState(false);
  const apiKey = JSON.parse(sessionStorage.getItem("msa_user_data")).key.key;

  const handleToggle = () => {
    handleCopy(apiKey);
    setApiKeyDisplay(true);
  };

  return (
    // <Layout>

    <AccountLayout
      title=" Your API Keys"
      description="We have given you the power to build awesome apps that can integrate
         with our system but we only ask that you repay us the favour by keeping
         your passkeys secure and private. "
    >
      <div className={style["api__container--key"]}>
        <header>
          <h4>API Key</h4>
        </header>
        <hr />
        <p>{showAPikey ? apiKey : "**********************"}</p>
        <Btn
          state={showAPikey}
          apiKey={apiKey}
          handleCopy={handleCopy}
          handleToggle={handleToggle}
          setApiKeyDisplay={setApiKeyDisplay}
        />
        <span style={{color:"red"}}>{isCopied ? <>Copied to Clipboard !</> : ""}</span>
      </div>
      <div className={style["api__container--url"]}>
        <header>
          <h4>Base Url</h4>
        </header>
        <hr />

        <p>https://api.myserviceagent.net</p>
      </div>
    </AccountLayout>
    // </Layout>
  );
}

const Btn = ({ state, setApiKeyDisplay, handleToggle }) => {
  switch (state) {
    case true:
      return (
        <Button
          className="small--ghost"
          onClick={() => setApiKeyDisplay(false)}
        >
          <span style={{ margin: "0 auto", display: "inline-block" }}>
            Hide
          </span>
        </Button>
      );

    case false:
      return (
        <Button className="small--ghost" onClick={() => handleToggle()}>
          <span>
            <svg
              width="30"
              height="40"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 6.44434H3C2.17275 6.44434 1.5 7.11709 1.5 7.94434V15.4443C1.5 16.2716 2.17275 16.9443 3 16.9443H10.5C11.3273 16.9443 12 16.2716 12 15.4443V7.94434C12 7.11709 11.3273 6.44434 10.5 6.44434Z"
                fill="black"
              />
              <path
                d="M15 1.94434H7.5C7.10218 1.94434 6.72064 2.10237 6.43934 2.38368C6.15804 2.66498 6 3.04651 6 3.44434V4.94434H12C12.3978 4.94434 12.7794 5.10237 13.0607 5.38368C13.342 5.66498 13.5 6.04651 13.5 6.44434V12.4443H15C15.3978 12.4443 15.7794 12.2863 16.0607 12.005C16.342 11.7237 16.5 11.3422 16.5 10.9443V3.44434C16.5 3.04651 16.342 2.66498 16.0607 2.38368C15.7794 2.10237 15.3978 1.94434 15 1.94434Z"
                fill="black"
              />
            </svg>
          </span>
          <span style={{ margin: " auto 1rem", display: "inline-block" }}>
            Copy
          </span>
        </Button>
      );

    default:
      return null;
  }
};
