import React from "react";
import style from "./index.module.scss";

const TagInput = ({
  tagInputs,
  setTagInputs,
  inputType,
  isPhonebook,
  placeholder,
  setRecipient,
  recipient,
}) => {
  const handleInputChange = (e) => {
    setRecipient(e.target.value);
  };

  // const handleInputKeyPress = (e) => {
  //   if (e.key === "Enter" || e.key === "," || e.key === " ") {
  //     e.preventDefault();
  //     addTag();
  //   }
  // };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addTag();
    } else if (e.key === "," || e.key === " ") {
      e.preventDefault();
      e.preventDefault();
      // Custom logic to handle special characters and split tags correctly
      const tagValue = recipient
        ?.split(/[, ]/)
        .filter((tag) => tag.trim() !== "") // Filter out empty tags
        .join(", "); // Rejoin the tags with a comma
      setRecipient(tagValue);
      addTag();
    }
  };

  const addTag = () => {
    if (recipient && recipient.trim() !== "") {
      const newTags = [...tagInputs, recipient.trim()];
      setTagInputs(newTags);
      setRecipient("");
    }
  };

  const handleInputPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const pastedTags = pastedData.split(",").map((tag) => tag.trim());
    setTagInputs([...tagInputs, ...pastedTags]);
  };

  const removeTag = (i) => {
    setTagInputs(tagInputs.filter((tag, index) => i !== index));
  };

  return (
    <div className={style["tag-input--container"]}>
      <div className={style["tag-input--container__items"]}>
        {tagInputs.map((input, index) => (
          <div className={style["tag--item"]} key={index}>
            <p> {input || input?.msisdn}</p>

            {!isPhonebook && (
              <span onClick={() => removeTag(index)}>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.01026 5.00021L9.79103 1.21947C10.0703 0.940185 10.0703 0.488753 9.79103 0.209466C9.51174 -0.0698219 9.06031 -0.0698219 8.78102 0.209466L5.00025 3.99021L1.21948 0.209466C0.940192 -0.0698219 0.488757 -0.0698219 0.209467 0.209466C-0.0698224 0.488753 -0.0698224 0.940185 0.209467 1.21947L3.99024 5.00021L0.209467 8.78096C-0.0698224 9.06024 -0.0698224 9.51167 0.209467 9.79096C0.348755 9.93025 0.531615 10.0002 0.714474 10.0002C0.897334 10.0002 1.08019 9.93025 1.21948 9.79096L5.00025 6.01022L8.78102 9.79096C8.92031 9.93025 9.10317 10.0002 9.28603 10.0002C9.46889 10.0002 9.65174 9.93025 9.79103 9.79096C10.0703 9.51167 10.0703 9.06024 9.79103 8.78096L6.01026 5.00021Z"
                    fill="#BC0C13"
                  />
                </svg>
              </span>
            )}
          </div>
        ))}
        <input
          style={{
            background: "transparent",
          }}
          placeholder={
            isPhonebook ? "" : placeholder ?? " eg. +2348142394015, +12345678"
          }
          type="text"
          value={recipient}
          onPaste={handleInputPaste}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          on
        />
      </div>
    </div>
  );
};

export default TagInput;
