import React from "react";
import AccountLayout from "../../../../components/Layout/AccountLayout";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "../../../../components/Button";
import eyeClose from "../../../../assets/icons/eye-close.png";
import eyeOpen from "../../../../assets/icons/eye-open.png";
import style from "./index.module.scss";
import * as authActions from "../../../../redux/actions/authActions";
import * as messageActions from "../../../../redux/actions/messageActions";
import { useDispatch, useSelector } from "react-redux";
import MoonLoader from "react-spinners/MoonLoader";

import { css } from "@emotion/react";
import Modal from "../../../../components/Modal";

export default function ChangeUserPassword() {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.auth.userInfo.id);
  const isAuth = useSelector((state) => state.auth);

  const notification = useSelector((state) => state.message);

  const [open, setOpen] = React.useState(false);
  const token = sessionStorage.getItem("msa_jwt");

  const clientData = new FormData();
  clientData.append("client_id", id);
  clientData.append("token", token);

  const handleToggle = () => {
    setOpen(!open);

    dispatch(messageActions.clearErrorMessage());
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  return (
    <AccountLayout
      title=" Change Password"
      description="We have given you the power to set security access to your dashboard.
        Change your password using the form below."
    >
      <Modal isOpen={notification.id === "SUCCESS" || isAuth.isLoading}>
        {isAuth.isLoading ? (
          <MoonLoader css={override} size={80} color={"red"} loading={true} />
        ) : (
          <>
            <header>
              <h3>Password Updated Successfully</h3>
            </header>

            <Button className="medium--filled" onClick={() => handleToggle()}>
              Okay
            </Button>
          </>
        )}
      </Modal>
      <div className={style["changepassword__container--form"]}>
        <ChangePasswordForm id={id} token={token} handleToggle={handleToggle} />
      </div>
    </AccountLayout>
  );
}

const ChangePasswordForm = ({ token, id, handleToggle }) => {
  const [showPassword, setShowPassword] = React.useState({ id: null });

  const lowCaseRegex = new RegExp(/^(?=.*[a-z])/);
  const upperCaseRegex = new RegExp(/^(?=.*[A-Z])/);
  const specialCaseRegex = new RegExp(/^(?=.*[!@#$%^&*])/);

  const initialValues = {
    new_password: "",
    old_password: "",
    confirm_password: "",
  };

  const dispatch = useDispatch();

  const passwordResetSchema = Yup.object().shape({
    new_password: Yup.string()
      // .required("Password is required.")
      .matches(
        lowCaseRegex,
        "Password must contain at least one lowercase character."
      )
      .matches(
        upperCaseRegex,
        "Password must contain at least one uppercase character."
      )
      .matches(
        specialCaseRegex,
        "Password must contain at least one special character."
      )
      .min(8, "Please fill in a minimum of 8 characters."),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const togglePasswordVisibility = (e) => {
    // setShowPassword(!showPassword);
    const { target } = e;
    let id = target.id;
    id === showPassword.id
      ? setShowPassword({ ...showPassword, id: "" })
      : setShowPassword({ ...showPassword, id });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={passwordResetSchema}
        onSubmit={({ new_password }) => {
          const formData = new FormData();

          // formData.append("old_password", old_password);
          formData.append("new_password", new_password);
          formData.append("token", token);

          dispatch(authActions.passwordUpdate(formData, "SUCCESS"));
        }}
      >
        {({ errors, touched, isValid, dirty, handleSubmit }) => {
          return (
            <Form>
              <div className={style["form__group"]}>
                <label htmlFor="">Old Password</label>
                <Field
                  type={
                    showPassword.id === "old_password" ? "text" : "password"
                  }
                  name="old_password"
                  id="confirmPassword"
                  placeholder="Old password"
                  className={
                    errors.confirmPassword && touched.confirmPassword
                      ? "input--error"
                      : null
                  }
                />
                <span
                  onClick={togglePasswordVisibility}
                  className={style["toggle__password"]}
                >
                  {showPassword.id === "old_password" ? (
                    <img id="old_password" src={eyeOpen} alt="eyeOpen" />
                  ) : (
                    <img id="old_password" src={eyeClose} alt="eyeClose" />
                  )}
                </span>
                <ErrorMessage
                  name="old_password"
                  component="span"
                  className="error"
                />
              </div>

              <div className={style["form__group"]}>
                <label htmlFor="">New Password*</label>
                <Field
                  type={
                    showPassword.id === "new_password" ? "text" : "password"
                  }
                  name="new_password"
                  id="password"
                  placeholder="Enter New password"
                  className={
                    errors.new_password && touched.new_password
                      ? "input--error"
                      : null
                  }
                />
                <span
                  onClick={togglePasswordVisibility}
                  className={style["toggle__password"]}
                >
                  {showPassword.id === "new_password" ? (
                    <img id="new_password" src={eyeOpen} alt="eyeOpen"></img>
                  ) : (
                    <img id="new_password" src={eyeClose} alt="eyeClose"></img>
                  )}
                </span>
                <ErrorMessage
                  name="new_password"
                  component="span"
                  className="error"
                />
              </div>
              <div className={style["form__group"]}>
                <label htmlFor="">Confirm Password*</label>
                <Field
                  type={
                    showPassword.id === "confirm_password" ? "text" : "password"
                  }
                  name="confirm_password"
                  id="password"
                  placeholder="Confirm New password"
                  className={
                    errors.confirm_password && touched.confirm_password
                      ? "input--error"
                      : null
                  }
                />

                <span
                  onClick={togglePasswordVisibility}
                  className={style["toggle__password"]}
                >
                  {showPassword.id === "confirm_password" ? (
                    <img
                      id="confirm_password"
                      src={eyeOpen}
                      alt="eyeOpen"
                    ></img>
                  ) : (
                    <img
                      id="confirm_password"
                      src={eyeClose}
                      alt="eyeClose"
                    ></img>
                  )}
                </span>
                <ErrorMessage
                  name="confirm_password"
                  component="span"
                  className="error"
                />
              </div>

              <Button
                type="submit"
                className="large--filled"
                // disabled={!(dirty && isValid)}
              >
                Save New password →
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
