import React, { useRef } from "react";
import Button from "../../../../components/Button";
import Layout from "../../../../components/Layout/DashboardLayout";
import WalletLayout from "../../../../components/Layout/WalletLayout";
import style from "./index.module.scss";
import * as paymentActions from "../../../../redux/actions/paymentActions";
import * as authActions from "../../../../redux/actions/authActions";
import * as messageActions from "../../../../redux/actions/messageActions";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs"; // ES 2015
import Modal from "../../../../components/Modal";
import { FormRowGroup } from "../../../../components/Form-row";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import axios from "../../../../utils/axios-base";

export default function WalletCredit() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [openModal, setOpen] = React.useState(false);

  const handleModal = () => {
    setOpen(!openModal);
  };

  const [currency, setCurrency] = React.useState("NGN");

  return (
    <Layout pageTitle="Wallet">
      <Modal isOpen={openModal} toggleModal={handleModal}>
        <div className={style["wallet--pricing"]}>
          <header>
            <h3>Our Service Prices</h3>
          </header>
          <FormRowGroup>
            <select name="" id="" onChange={(e) => setCurrency(e.target.value)}>
              <option value="NGN">NGN</option>
              <option value="USD">USD</option>
            </select>
          </FormRowGroup>

          <table>
            <thead>
              <tr>
                <th>Services</th>
                <th>Prices</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>SMS</td>
                <td>
                  Starting @ {currency === "NGN" ? "NGN2,000" : "USD 4.58"}
                </td>
              </tr>
              <tr>
                <td>Voice</td>
                <td>
                  Starting @ {currency === "NGN" ? "NGN 2,100" : "USD 5.00"}
                </td>
              </tr>
            </tbody>
          </table>
          <Button onClick={() => handleModal()} className="filled">
            Continue to Top-up
          </Button>
        </div>
      </Modal>
      <WalletLayout>
        <section>
          <header>
            <h3>Hi,{userInfo?.contact_person} </h3>
            <p>
              Check out full messaging prices&nbsp;
              <span
                style={{
                  color: "red",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => handleModal()}
              >
                here
              </span>
            </p>
          </header>
          <ATMDisplay dispatch={dispatch} />
        </section>
        <section className={style["top-"]}>
          <TopUp
            dispatch={dispatch}
            handleChange={(e) => setCurrency(e.target.value)}
            currency={currency}
          />
          <h4>Note:</h4>
          <p>
            Note: All payments would be remitted in {currency},but <br /> your
            accounts would be credited in units.
          </p>
        </section>
      </WalletLayout>
    </Layout>
  );
}

const ATMDisplay = ({ dispatch }) => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const PurchaseHistory =
    useSelector(
      (state) => state.payments.purchase_history?.data[0]?.created_at
    ) || null;

  // console.log(PurchaseHistory);
  React.useEffect(() => {
    const clientData = new FormData();
    const token = JSON.parse(sessionStorage.getItem("msa_user_data")).token;
    const id = JSON.parse(sessionStorage.getItem("msa_user_data")).id;

    clientData.append("client_id", id);
    clientData.append("token", token);

    dispatch(authActions.getClientInfo(clientData));
    // dispatch(paymentActions.getPurchaseHistory(clientData));

    return () => {
      dispatch(messageActions.clearErrorMessage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(PurchaseHistory)
  return (
    <div className={style["wallet--atmDisplay"]}>
      <div></div>
      <header>
        <p>BALANCE</p>
        <h3>{userInfo?.airtime_balance}</h3> <span>units</span>
      </header>

      <div>
        <label>
          Last Recharge{" "}
          {PurchaseHistory && dayjs(PurchaseHistory).format("DD/MM/YYYY")}
        </label>
        <Link to="/wallet/history">View Purchase History</Link>
      </div>
    </div>
  );
};

const TopUp = ({ dispatch, currency, handleChange }) => {
  const email = useSelector((state) => state.auth.userInfo?.email);
  const token = JSON.parse(sessionStorage.getItem("msa_user_data")).token;
  const id = JSON.parse(sessionStorage.getItem("msa_user_data")).id;
  const notification = useSelector((state) => state.message);
  const [open, isOpen] = React.useState(false);

  const handleBankModal = () => {
    isOpen(!open);
  };

  const getClientInfo = () => {
    const clientData = new FormData();
    clientData.append("client_id", id);
    clientData.append("token", token);
    dispatch(authActions.getClientInfo(clientData, "GET_NEW_DATA"));
    dispatch(messageActions.clearErrorMessage());
  };

  notification.id === "PAID" && getClientInfo();
  const paymentRef = useRef();

  function payWithPaystack(values, currency, actions) {
    // e.preventDefault();
    let handler = window.PaystackPop.setup({
      key: "pk_live_9f46f712bf98af5176a1b850c2bd507b21124301",
      email,
      amount: values * 100,
      currency: currency,
      ref: "" + Math.floor(Math.random() * 1000000000 + 1),
      onClose: function () {
        // alert("Window closed.");
      },

      callback: function (response) {
        const formData = new FormData();

        formData.append("client_email", email);
        formData.append("client_id", id);
        formData.append("reference", response.reference);
        formData.append("token", token);

        dispatch(paymentActions.makePayment(formData));
        actions.resetForm();
      },
    });

    handler.openIframe();
  }

  const inputSchema = Yup.object().shape({
    money_value: Yup.number().when("currency", {
      is: "NGN",
      then: () =>
        Yup.number()
          .required("Required")
          .positive("Value cannot be negative")
          .typeError("Amount must be a number")
          .min(2000, "Minimum amount is N2000"),
    }),

    payment_method: Yup.string().when("currency", {
      is: "NGN",
      then: () => Yup.string().required("Please Select a payment method"),
    }),
    // currency: Yup.string().required("Please Select a currency"),
  });

  const [amount, setAmount] = React.useState("2000");
  const [units, setUnits] = React.useState(null);

  React.useEffect(() => {
    const fetchUnitAmount = () => {
      if (amount) {
        axios
          .get(`/conversion?amount=${amount}&currency=${currency}`)
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data.data);
              setUnits(res.data.data);
            }
          });
      }
    };

    fetchUnitAmount();
  }, [amount, currency]);

  return (
    <div className={style["wallet--card-info"]}>
      <header>
        <h4>Fund Wallet</h4>
      </header>
      {units && (
        <p>
          Minimum Amount to recharge is{" "}
          <span style={{ color: "red" }}>{`NGN ${new Intl.NumberFormat().format(
            units?.minimum_amount_naira
          )} (USD ${units?.minimum_amount_dollar})`}</span>
        </p>
      )}
      <Formik
        initialValues={{
          units_value: "",
          money_value: "",
          payment_method: "",
          currency: "",
        }}
        validationSchema={inputSchema}
        onSubmit={({ payment_method }, actions) => {
          // handleSubmity({money_value,payment_method});
          if (payment_method === "bank") {
            handleBankModal();
          } else {
            return payWithPaystack(amount, currency, actions);
          }

          // setUnits({...units, units:values.money_value})
        }}
      >
        {({ handleSubmit, values }) => (
          <Form ref={paymentRef} className={style["wallet--inputs"]}>
            <FormRowGroup>
              <label>Currency</label>
              <select onChange={(e) => handleChange(e)} name="currency">
                <option value="" selected>
                  Select preferred currency
                </option>
                <option value="USD">USD</option>
                <option value="NGN">NGN</option>
              </select>
              {!currency ? (
                <span className="error">Please select a currency</span>
              ) : (
                ""
              )}
            </FormRowGroup>
            <FormRowGroup>
              <input
                type="tel"
                name="money_value"
                placeholder="Amount eg. 15,000"
                onChange={(e) => setAmount(e.target.value)}
              />
              {currency && !amount ? (
                <span className="error">"Required"</span>
              ) : currency === "USD" &&
                amount < units?.minimum_amount_dollar ? (
                <span className="error">{`Minimum amount in USD is ${units?.minimum_amount_dollar}`}</span>
              ) : currency === "NGN" && amount < 2000 ? (
                <span className="error">Minimum amount in NGN is 2000</span>
              ) : (
                ""
              )}
            </FormRowGroup>

            <FormRowGroup>
              <input
                type="tel"
                name="money_value"
                placeholder="Units eg. 100"
                value={units?.units}
                disabled
              />
              <ErrorMessage
                name="units_value"
                component="span"
                className="error"
              />
            </FormRowGroup>
            <FormRowGroup>
              <label>
                Payment Method <ToolTip />
              </label>

              <Field name="payment_method" as="select" id="">
                {currency === "NGN" && (
                  <>
                    <option value="">Please Field an option</option>
                    <option value="bank">Bank Transfer</option>
                  </>
                )}
                <option value="card">Card</option>
              </Field>
            </FormRowGroup>
            <ErrorMessage
              name="payment_method"
              component="span"
              className="error"
            />
            <PaymentInstructions open={open} handleModal={handleBankModal} />
            <Button
              disabled={!currency || !amount}
              // type="submit"
              className="filled"
              onClick={(e) => handleSubmit(e)}
            >
              Pay
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const PaymentInstructions = ({ open, handleModal }) => {
  const { values } = useFormikContext();
  return (
    <Modal isOpen={open} toggleModal={handleModal}>
      <div className={style["bankPayment--container"]}>
        <header>
          <p>
            Transfer <strong>NGN</strong> {values.money_value} to MyServiceAgent
          </p>
        </header>
        <div className={style["bankPayment--info"]}>
          <p>Bank: Zenith Bank</p>
          <p>Account Name: MSA TECHNOLOGIES LIMITED</p>
          <h3>Account Number: 1221044097</h3>
        </div>

        <hr />

        <p>Click the button below to upload transfer details</p>

        <NavLink to="/wallet/paymentevidence">
          <Button type="button" className="filled">
            Upload Payment Evidence
          </Button>
        </NavLink>
      </div>
    </Modal>
  );
};

const ToolTip = () => {
  return (
    <span className={style["payment__method--info"]}>
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        className={style["fa-icon"]}
        AwesomeIcon
        icon={faQuestion}
      />
      <p>
        You can pay using your Card on the platform. Or you could make payment
        using your preferred banking method and then upload payment evidence
      </p>
    </span>
  );
};
