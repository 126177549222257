import React from "react";
import DatePicker from "react-datepicker";
import { filters, otpFilters, smsFilters } from "../../../../utils/filtersJson";
import style from "./index.module.scss";

export const DropDown = ({ handleSelect }) => {
  return (
    <div className={style["dropdown"]}>
      <div className={style["actions"]}>
        {filters.map((item, index) => (
          <p
            className={style["action"]}
            key={index}
            onClick={() => handleSelect(item)}
          >
            <span>
              By {item.by} {item.by.includes("Duration") ? "(seconds)" : ""}
            </span>
            <span>{item.icon}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export const DateDropDown = ({
  handleStartDate,
  handleEndDate,
  startDate,
  endDate,
}) => {
  return (
    <div className={style["date-dropdown"]}>
      <div className={style["actions"]}>
        <span>
          <DatePicker
            selected={startDate} //when day is clicked
            onChange={(date) => handleStartDate(date)}
            maxDate={new Date()}
            // inline
            placeholderText="Start date"
            showMonthDropdown
            showYearDropdown // dateFormat="DD/MM/YYYY"
          />
        </span>

        <span>
          <DatePicker
            selected={endDate} //when day is clicked
            onChange={(date) => handleEndDate(date)}
            maxDate={new Date()}
            placeholderText="End date"
            showMonthDropdown
            showYearDropdown
          />
        </span>
      </div>
    </div>
  );
};

export const DurationDropDown = ({ handleMinDuration, handleMaxDuration }) => {
  return (
    <div className={style["duration-dropdown"]}>
      <div className={style["actions"]}>
        <span>
          <input
            type="number"
            placeholder="Minimum"
            onChange={(e) => handleMinDuration(e.target.value)}
          />
        </span>

        <span>
          <input
            type="number"
            placeholder="Maximum"
            onChange={(e) => handleMaxDuration(e.target.value)}
          />
        </span>
      </div>
    </div>
  );
};

// Otp dropdowns

export const OtpDropDown = ({ handleSelect }) => {
  return (
    <div className={style["otp-dropdown"]}>
      <div className={style["actions"]}>
        {otpFilters.map((item, index) => (
          <p
            className={style["action"]}
            key={index}
            onClick={() => handleSelect(item)}
          >
            <span>By {item.by}</span>
            <span>{item.icon}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export const DeliveryStatus = ({ handleClick }) => {
  return (
    <div className={style["delivery-status"]}>
      <div className={style["actions"]}>
        {otpDeliveryStatuses.map((item, index) => (
          <p
            className={style["action"]}
            key={index}
            onClick={() => handleClick(item)}
          >
            <span>{item}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export const OTPStatus = ({ handleClick }) => {
  return (
    <div className={style["otp-status"]}>
      <div className={style["actions"]}>
        {otpStatuses.map((item, index) => (
          <p
            className={style["action"]}
            key={index}
            onClick={() => handleClick(item)}
          >
            <span>{item}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export const SmsDropDown = ({ handleSelect }) => {
  return (
    <div className={style["otp-dropdown"]}>
      <div className={style["actions"]}>
        {smsFilters.map((item, index) => (
          <p
            className={style["action"]}
            key={index}
            onClick={() => handleSelect(item)}
          >
            <span>By {item.by}</span>
            <span>{item.icon}</span>
          </p>
        ))}
      </div>
    </div>
  );
};
export const SmsStatus = ({ handleClick }) => {
  return (
    <div className={style["otp-status"]}>
      <div className={style["actions"]}>
        {smsStatus.map((item, index) => (
          <p
            className={style["action"]}
            key={index}
            onClick={() => handleClick(item)}
          >
            <span>{item}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export const CampaignDateFilteerDropDown = ({
  handleStartDate,
  handleEndDate,
  startDate,
  endDate,
}) => {
  return (
    <div className={style["campaign-date-dropdown"]}>
      <div className={style["actions"]}>
        <span>
          <DatePicker
            selected={startDate} //when day is clicked
            onChange={(date) => handleStartDate(date)}
            maxDate={new Date()}
            // inline
            placeholderText="Start date"
            showMonthDropdown
            showYearDropdown // dateFormat="DD/MM/YYYY"
          />
        </span>

        <span>
          <DatePicker
            selected={endDate} //when day is clicked
            onChange={(date) => handleEndDate(date)}
            maxDate={new Date()}
            placeholderText="End date"
            showMonthDropdown
            showYearDropdown
          />
        </span>
      </div>
    </div>
  );
};

export const ToolTip = () => {
  return (
    <div className={style["tooltip"]}>
      <ol>
        <li>sent: The message has been sent by our system.</li>
        <li>
          submitted: The message has been submitted to the MNO (Mobile network
          operator).
        </li>
        <li>
          rejected: The message has been rejected by the MNO. This is a final
          status.
        </li>
        <li>
          success: The message has successfully been delivered to the receiver’s
          handset.
          <br /> This is a final status.
        </li>
        <li>
          failed: The message could not be delivered to the receiver’s handset.{" "}
          <br />
          This is a final status.
        </li>
      </ol>
    </div>
  );
};
const otpDeliveryStatuses = [
  "Sent",
  "Rejected",
  "Buffered",
  "Submitted",
  "Success",
  "Failed",
];
const otpStatuses = ["New", "Processed", "Verified", "Expired"];

const smsStatus = [
  "Queued",
  "Sent",
  "Rejected",
  "Buffered",
  "Submitted",
  "Success",
  "Failed",
];
