import React from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "../Button";
// import style from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import "react-datepicker/dist/react-datepicker.css";
import nigerianBanks from "../../utils/JSON/nigerian-banks.json";
// import Layout from "../Layout/DashboardLayout";
// import WalletLayout from "../Layout/WalletLayout";

import { FormRowGroup } from "../Form-row/index.jsx";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useDispatch, useSelector } from "react-redux";
import { bankTransferPayment } from "../../redux/actions/paymentActions";
import Modal from "../Modal";
import { clearErrorMessage } from "../../redux/actions/messageActions";
import FileUpload from "../FIleUpload";
import style from "./index.module.scss";
import DatePickerField from "../DatePickerField";

const PaymentEvidenceComponent = () => {
  const dispatch = useDispatch();
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  dayjs.extend(localizedFormat);

  const client_id = useSelector((state) => state.auth.userInfo.id);
  const client_email = useSelector((state) => state.auth.userInfo.email);
  const isLoading = useSelector((state) => state.payments.isLoading);
  const notification = useSelector((state) => state.message);

  const paymentEvidenceFormSchema = Yup.object().shape({
    bank_used: Yup.string().required("Required"),
    // reference: Yup.string().required("Reference number is required"),
    payment_evidence: Yup.mixed()
      .nullable()
      .required("Please upload a payment evidence"),

    amount_transferred: Yup.number()
      .required("Amount Transferred required.")
      .positive("Amount cannot be negative")
      .typeError("Amount must be a number"),

    sender_name: Yup.string().required("Sender Name is required."),

    transaction_date: Yup.date().required("Transaction date is required."),
  });

  const initialValues = {
    client_id,
    bank_used: "",
    amount_transferred: "",
    sender_name: "",
    client_email: "",
    reference: "",
    transaction_date: "",
    payment_evidence: null,
    token: sessionStorage.getItem("msa_jwt"),
  };

  const handleFormReset = (action) => {
    console.log(action);
    if (notification.id === "Transfer") {
      action();
      dispatch(clearErrorMessage());
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={paymentEvidenceFormSchema}
        onSubmit={(
          {
            bank_used,
            amount_transferred,
            sender_name,
            transaction_date,
            reference,
            payment_evidence,
            token,
          },
          actions
        ) => {
          const formData = new FormData();

          formData.append("client_id", client_id);
          formData.append("client_email", client_email);
          formData.append("amount_paid", amount_transferred);
          formData.append("name", sender_name);
          formData.append("bank", bank_used);
          formData.append("date", dayjs(transaction_date).format("YYYY-MM-DD"));
          formData.append("payment_evidence", payment_evidence);
          formData.append("token", token);

          //   handleSubmit(fields)
          dispatch(bankTransferPayment(formData));
          handleFormReset(actions);

          actions.setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          resetForm,
          values,
        }) => {
          return (
            <Form>
              <FormRowGroup>
                <label htmlFor="bank_used">Bank Used*</label>
                <Field as="select" name="bank_used" id="bank_used">
                  <option value="">Please Select an option</option>
                  {nigerianBanks.map((item, id) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="bank_used"
                  component="span"
                  className="error"
                />
              </FormRowGroup>

              <FormRowGroup>
                <label htmlFor="amount_transferred">Amount Transferred*</label>
                <Field
                  type="tel"
                  name="amount_transferred"
                  id="amount_transferred"
                  placeholder="Enter Amount Transferred"
                  className={
                    errors.amount_transferred && touched.amount_transferred
                      ? "input--error"
                      : null
                  }
                />
                <ErrorMessage
                  name="amount_transferred"
                  component="span"
                  className="error"
                />
              </FormRowGroup>

              <FormRowGroup>
                <label htmlFor="sender_name">Bank Account Name *</label>
                <Field
                  type="text"
                  name="sender_name"
                  id="sender_name"
                  placeholder="Enter Sender Name*"
                  className={
                    errors.sender_name && touched.sender_name
                      ? "input--error"
                      : null
                  }
                />
                <ErrorMessage
                  name="sender_name"
                  component="span"
                  className="error"
                />
              </FormRowGroup>
              <FormRowGroup>
                <label htmlFor="transaction_date">Date of Transaction*</label>
                <DatePickerField
                  name="transaction_date"
                  value={values.transaction_date}
                  className={
                    errors.transaction_date && touched.transaction_date
                      ? "input--error"
                      : null
                  }
                />

                <ErrorMessage
                  name="transaction_date"
                  component="span"
                  className="error"
                />
              </FormRowGroup>
              <FormRowGroup>
                <FileUpload
                  setFieldValue={setFieldValue}
                  values={values}
                  name="payment_evidence"
                  accept="image/jpg, image/png"
                >
                  <p className={style["filename"]}>
                    {values.payment_evidence?.name}
                  </p>
                </FileUpload>
                <ErrorMessage
                  name="payment_evidence"
                  component="span"
                  className="error"
                />
              </FormRowGroup>

              <Button
                type="submit"
                className="filled"
                disabled={isSubmitting}
                // disabled={!dirty && !isValid}
              >
                {!isLoading ? (
                  "Upload Payment Details"
                ) : (
                  <ScaleLoader
                    css={override}
                    size={80}
                    color={"#fff"}
                    loading={true}
                  />
                )}{" "}
              </Button>
              <Modal isOpen={notification.id === "Transfer"} toggleModal="">
                <div>
                  <header>
                    <h3>Upload Successful</h3>
                    <p>
                      Thank you. Your payment evidence has been successfully
                      uploaded. Expect a response from us within 24 hours.
                    </p>
                  </header>
                  <Button
                    type="button"
                    className="filled"
                    onClick={() => handleFormReset(resetForm)}
                  >
                    Continue
                  </Button>
                </div>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PaymentEvidenceComponent;
